import { userAxios } from '../../index'

const campaignList = async (paginate) => {
    try {
        return await userAxios.get(`cupons/all/${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const campaignCreate = async (data) => {
    try {
        return await userAxios.post('cupons', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const campaignUpdate = async (id, data) => {
    try {
        return await userAxios.put(`cupons/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const campaignDelete = async (id) => {
    try {
        return await userAxios.delete(`cupons/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const campaignPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`cupons/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const campaignFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`cupons/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const campaignFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`cupons/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    campaignList,
    campaignCreate,
    campaignUpdate,
    campaignDelete,
    campaignPagination,
    campaignFilter,
    campaignFilterPagination
}
