<template>
    <b-modal :visible="isCuponsModalActive" @change="(val) => $emit('update:isCuponsModalActive', val)"
        :title="campaignName" size="lg">
        <b-table :items="cupons" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
            show-empty empty-text="No cupons found!">
            <template #cell(created_at)="data">
                {{ new Date(data.item.created_at).toLocaleDateString() }}
            </template>
            <template #cell(status)="data">
                {{ checkStatus(data.item.status) }}
            </template>
        </b-table>
    </b-modal>
</template>

<script>
import {
    BModal,
    BRow,
    BCol,
    BButton,
    BTable
} from "bootstrap-vue";
import { getStatus } from "@/mixins/cupon";

export default {
    data() {
        return {
            fields: [
                "id",
                "cupon",
                "status",
                "ip_client",
                "phone_number",
                "created_at"
            ]
        }
    },
    components: {
        BRow,
        BCol,
        BButton,
    },
    model: {
        prop: "isCuponsModalActive",
        event: "update:isCuponsModalActive",
    },
    props: {
        isCuponsModalActive: {
            type: Boolean,
            default: false,
            required: true
        },
        campaignName: {
            type: String,
            default: "",
            required: true
        },
        cupons: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    methods: {
        checkStatus(status) {
            return getStatus(status)
        }
    },
};
</script>