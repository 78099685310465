<template>
    <b-sidebar id="add-new-laboratory-category-sidebar" :visible="isEditLaboratoryCategorySidebarActive" bg-variant="white"
        sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
        @change="(val) => $emit('update:is-edit-laboratory-category-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="
                  d-flex
                  justify-content-between
                  align-items-center
                  content-sidebar-header
                  px-2
                  py-1
                ">
                <h5 class="mb-0">Edit Laboratory Category</h5>

                <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <validation-provider #default="validationContext" name="name" rules="required">
                        <b-form-group label="Name" label-for="name">
                            <b-form-input id="name" v-model="stateData.name" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Type Campaign name" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="description" rules="required">
                        <b-form-group label="Description" label-for="description">
                            <b-form-input id="description" v-model="stateData.description" autofocus
                                :state="getValidationState(validationContext)" trim
                                placeholder="Type Campaign description" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="prefix" rules="required">
                        <b-form-group label="prefix" label-for="prefix">
                            <b-form-input id="prefix" v-model="stateData.prefix" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Type Campaign prefix" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="quantity" rules="required">
                        <b-form-group label="quantity" label-for="quantity">
                            <b-form-input id="quantity" v-model="stateData.quantity" autofocus
                                :state="getValidationState(validationContext)" type="number" trim
                                placeholder="Type Campaign quantity" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="cupons per patient" rules="required">
                        <b-form-group label="cupons per patient" label-for="cupons per patient">
                            <b-form-input id="cupons per patient" v-model="stateData.cupons_per_patient" autofocus
                                :state="getValidationState(validationContext)" trim
                                placeholder="Type Campaign cupons per patient" type="number" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="end date" rules="required">
                        <b-form-group label="end date" label-for="end date">
                            <flat-pickr v-model="stateData.end_date" :config="config" class="form-control" type="date" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="type" rules="required">
                        <b-form-group label="type" label-for="type">
                            <treeselect v-model="stateData.type" class="rounded" style="background: #fff"
                                :options="typeOptions" :clearable="true" :normalizer="normalizer"
                                placeholder="Type Campaign type" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="value" rules="required">
                        <b-form-group label="value" label-for="value">
                            <b-form-input type="number" id="value" v-model="stateData.value"
                                :state="getValidationState(validationContext)" placeholder="Type a value" trim />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <!-- status -->
                    <validation-provider #default="validationContext" name="facilities" rules="required">
                        <b-form-group label="facilities" label-for="facilities">
                            <treeselect v-model="stateData.facilities" class="rounded" style="background: #fff"
                                :options="facilityOptions" :multiple="true" :clearable="true" :normalizer="normalizer"
                                :value-format="facilityOptions.length === 1 ? 'object' : 'id'" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Message" rules="required">
                        <b-form-group label="Message" label-for="Message">
                            <b-form-textarea v-model="stateData.message" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider #default="validationContext" name="Status" rules="required">
                        <b-form-group label="Status" label-for="status">
                            <treeselect id="status" v-model="stateData.status"
                                :state="getValidationState(validationContext)" :options="optionsStatus"
                                :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                            <span v-if="!loading">Edit</span>
                            <span v-else>
                                <SpinnerLoading />
                            </span>
                        </b-button>
                        <b-button type="button" variant="outline-danger" @click="hide">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";
import axios from "@/core/services/api/admin/consults/campaigns";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import Swal from "sweetalert2";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        SpinnerLoading,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "isEditLaboratoryCategorySidebarActive",
        event: "update:is-edit-laboratory-category-sidebar-active",
    },
    props: {
        isEditLaboratoryCategorySidebarActive: {
            type: Boolean,
            required: true,
        },
        itemEdit: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            required,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    setup(props, { emit }) {
        const optionsStatus = [
            { name: "Active", id: true },
            { name: "Inactive", id: false },
        ];
        const facilityOptions = JSON.parse(atob(localStorage.getItem("setFacilityOptions")));
        const config = {
            dateFormat: "Y-m-d",
            allowInput: true,
        }
        const typeOptions = [
            { id: "percentage", name: "percentage" },
            { id: "value", name: "value" }
        ]
        const stateDataTem = {
            name: "",
            description: '',
            prefix: '',
            message: '',
            quantity: 0,
            cupons_per_patient: 0,
            end_date: '',
            type: 'percentage',
            value: 0,
            facilities: [],
            status: true,
        };
        const optionsLabs = props.itemEdit.value.facilities

        const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)));
        stateData.value.facilities = props.itemEdit.value.facilities.map((lab) => {
            return lab.id
        })
        const resetuserData = () => {
            stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
        };
        const loading = ref(false);
        const onSubmit = () => {
            loading.value = true;
            if (stateData.value.price <= 0) {
                loading.value = false;
                return Swal.fire({
                    title: "Incorrect price",
                    text: "you must put a price greater than 0",
                })
            }
            const LaboratoryCategoryItem = JSON.parse(JSON.stringify(stateData));
            axios.campaignUpdate(LaboratoryCategoryItem.value.id, LaboratoryCategoryItem.value).then(() => {
                loading.value = false;
                resetuserData();
                emit("editcampaignSuccess", true);
            }).catch(() => {
                emit("editcampaignSuccess", false);
                loading.value = false;
            });
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(
            resetuserData
        );
        return {
            stateData,
            loading,
            optionsStatus,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            optionsLabs,
            facilityOptions,
            config,
            typeOptions,
            stateDataTem
        };
    },
};
</script>

<style></style>
