<template>
    <b-modal :visible="seeCupons" @change="(val) => $emit('update:seeCupons', val)" size="xl" title="Cupons">
        <b-row>
            <b-col>
                <label>Start date</label>
                <flat-pickr v-model="filters.start_date" :config="config" class="form-control" placeholder="MM-DD-YYYY"
                    type="date" />
            </b-col>
            <b-col>
                <label>End date</label>
                <flat-pickr v-model="filters.end_date" :config="configEnd" class="form-control" placeholder="MM-DD-YYYY"
                    type="date" />
            </b-col>
            <b-col class="pt-2">
                <label>Facility</label>
                <treeselect v-model="filters.facilities_id" :async="true" :load-options="searchFacility"
                    :defaultOptions="true" :normalizer="normalizer" @keyup.enter="searchFilter" multiple />
            </b-col>
            <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
                <b-button variant="warning" @click="resetFilter" class="mr-1">
                    Clear
                </b-button>
                <b-button variant="primary" @click="searchFilter">
                    <span>Search</span>
                </b-button>
            </b-col>
        </b-row>
        <TotalCupons :datarda="tableData" />
        <b-table id="tablarda" :items="items" :fields="fields" hover responsive primary-key="id" class="position-relative"
            show-empty empty-text="No records found">
            <template #cell(view)="data">
                <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails" />
            </template>

            <template #cell(patient)="data">
                {{ data.item.patient_name }} {{ data.item.patient_lastname }}
            </template>

            <template #cell(show)="row">
                <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails" />
            </template>

            <template #row-details="row">
                <b-card>
                    <b-container>
                        <b-row>
                            <b-col cols="0">
                                <strong>Nurse:</strong>
                            </b-col>
                            <b-col>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ row.item.nurse_name }}
                                </span>
                            </b-col>
                            <b-col>
                                <strong>Visit Date : </strong>{{ row.item.visit_date }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="0">
                                <strong>Oma:</strong>
                            </b-col>
                            <b-col>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ row.item.oma_name }}
                                </span>
                            </b-col>
                            <b-col cols="0">
                                <strong>Frontdesk:</strong>
                            </b-col>
                            <b-col>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ row.item.frontdesk_name }}
                                </span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col>
                                <b-card title="Visit Reason: ">
                                    <span>
                                        {{ row.item.reason_visit }}
                                    </span>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-container>
                    <br />
                    <b-button class="mr-2" size="sm" variant="outline-secondary" @click="row.toggleDetails">
                        Hide Details
                    </b-button>
                    <b-button size="sm" variant="outline-secondary" @click="seeDetails(row.item)">
                        See details
                    </b-button>
                </b-card>
            </template>
        </b-table>
        <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
            @page-changed="pageChanged" />
        <ToastNotification ref="toast" />
    </b-modal>
</template>

<script>
import {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
} from "bootstrap-vue";

import TotalCupons from "./totalCupons";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import ToastNotification from "@/components/ToastNotification";
import axios from "@/core/services/api/admin/adminData";
import axiosF from "@/core/services/api/admin/facilities";
import axiosAD from "@/core/services/api/admin/adminData";

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        SpinnerLoading,
        ToastNotification,
        PaginationTable,
        TotalCupons
    },
    model: {
        prop: "seeCupons",
        event: "update:seeCupons",
    },
    props: {
        seeCupons: {
            type: Boolean,
            default: false,
            required: true
        },
    },
    data() {
        return {
            tableData: {},
            items: [],
            fields: [
                "show",
                "total",
                "ajustment",
                "cash",
                "cc",
                "consult_price",
                "patient",
                "facility",
                "type_visit",
                "visit_date",
                "pay_status",
                "visit_status",
            ],
            dataMeta: { from: 1, to: 0, of: 0 },
            totalUsers: null,
            perPage: null,
            currentPage: {
                page: 1
            },
            config: {
                dateFormat: "m-d-Y H:i:S",
                enableSeconds: true,
                allowInput: true,
                enableTime: true
            },
            configEnd: {
                dateFormat: "m-d-Y H:i:S",
                enableSeconds: true,
                allowInput: true,
                enableTime: true
            },
            visit: {},
            modalDetailBool: false,
            patient_name: '',
            filters: {
                start_date: `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()} 00:00:00`,
                end_date: `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()} 23:59:59`,
                facilities_id: []
            },
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    mounted() {
        const firstDate = `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`

        axiosAD.getAdminDataPaginated(1, { start_date: `${firstDate} 00:00:00`, end_date: `${firstDate} 23:59:59` }).then(({ data }) => {
            this.tableData = data
            this.setNewData(data)
        });
    },
    methods: {
        searchFilter() {
            if (!this.filters.start_date || !this.filters.end_date) return this.$refs.toast.warning("Check the dates!");
            axiosAD.getAdminData(this.filters).then(({ data }) => {
                this.tableData = data
                this.setNewData(data)
            });
        },
        searchFacility({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                };
                axiosF.facilityFilter(10, datos).then(({ data }) => {
                    callback(null, data);
                });
            }
        },
        setNewData({ CUPONS_PAGINATE: { data, current_page, from, total, to, per_page } }) {
            this.items = data
            this.dataMeta = { from, to, of: total }
            this.totalUsers = total
            this.perPage = per_page
            this.currentPage.page = current_page
        },
        resetFilter() {
            const filter = this.filters;
            for (const iterator in filter) {
                this.filters[iterator] = null;
            }
        },
        pageChanged(page) {
            axiosAD.getAdminDataPaginated(page, this.filters).then(({ data }) => {
            this.tableData = data
            this.setNewData(data)
        });
        },
        async seeDetails({ id, patient_name }) {
            const { office_visit } = await axios.getDetailsOfficeVisit(id)
            this.patient_name = patient_name
            this.visit = office_visit
            this.modalDetailBool = true
        },
    },
};
</script>

<style lang="scss" scoped></style>
